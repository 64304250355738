<template>
  <div class="booklistbox">
    <ul class="booklist" v-if="datalist.length > 0">
      <li v-for="item in datalist">
        <div class="onebox height40">
          <router-link
            tag="div"
            :to="{ name: 'bookIntroduction', params: { name: item.id } }"
            class="imgbox"
          >
            <img :src="item.avatar" alt />
          </router-link>
          <div class="rightbox">
            <label :class="{'isread':item.isread==2}" v-html="item.content"></label>
          </div>
          <i class="iconfont iconzhankai" @click="taggle"></i>
          <!-- <i class="iconfont iconshouqi"></i> -->
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    set: {
      set: Boolean,
      required: false,
    },
    datalist: {
      datalist: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    tid() {
      return this.$route.params.tid || "";
    },
  },
  methods: {
    taggle(e) {
      if (e.currentTarget.className == "iconfont iconzhankai") {
        e.currentTarget.className = "iconfont iconshouqi";
        e.toElement.parentNode.className = "onebox";
      } else {
        e.currentTarget.className = "iconfont iconzhankai";
        e.toElement.parentNode.className = "onebox height40";
      }
    },
  },
};
</script>
<style  lang="less" scoped>
.booklistbox {
  min-height: 480px;
}
.booklist {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  & > li {
    flex: 1;
    box-sizing: border-box;
    margin-bottom: 10px;
    width: 100%;
    .onebox {
      background: #fff;
      -webkit-box-shadow: 0px 0px 10px #e0e0e0;
      box-shadow: 0px 0px 10px #e0e0e0;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .height40 .rightbox {
      max-height: 40px;
      overflow: hidden;
    }
    .imgbox {
      border: 1px solid #e5e5e5;
      padding: 2px;
      width: 50px;
      height: 50px;
      border-radius: 70px;
      overflow: hidden;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .rightbox {
      width: 1040px;
      margin-top: 5px;
      color: #666;
      text-overflow: ellipsis;
      overflow: hidden;
      h2 {
        font-size: 14px;
        line-height: 22px;
      }
      .isread{
        font-weight: bold;
      }
    }
    .iconfont {
      font-size: 24px;
      width: 30px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.nomore {
  text-align: center;
  display: none;
}
</style>